<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">

#app {
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  box-sizing: border-box;
  // margin-bottom: -20px;
  // background:  orange;


  // height: 100%;
  // overflow: hidden;

}

#nav {
  padding: 30px;
  
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
 .html-body-overflow { overflow-x:hidden; overflow-y:hidden; } 
</style>


<script>
export default {
  data() {
    return {
      userId: 1,
      name: 'Unknown User',
      email: '',
      appid:"uu6ddns6",
      isID:false,
    }
  },
  created() {
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
    this.userId = 'arcweb' + new Date().getTime();
    // Vue.forceUpdate();
  },
  mounted(){
    

    // (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.defer=true;s.src='https://widget.intercom.io/widget/uu6ddns6';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    // window.Intercom('boot',{
    //       app_id: this.appid,
    //       user_id: this.userId,
    //       name: this.name,
    //       email: this.email,
    //     })
  },
  watch: {
    // email(email) {
    //     Intercom("update",{ email:this.email })
    // },
    // name(name) {
    //     Intercom("update",{ name:this.name })
    // },
    // userId(user_id) {
    //     Intercom("update",{ user_id:this.userId })
    // },
    methods: {
    MathRand() {
      var Num = "";
      for (var i = 0; i < 6; i++) {
        Num += Math.floor(Math.random() * 10);
      }
      return Num;
    },
  },
}
}
</script>

