import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)


let isPhone = function () {
  let userAgentInfo = navigator.userAgent;
  let Agents = ['Android', "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  let flag = true;
  
  // 判断手机的系统
  // if(userAgentInfo.indexOf("Safari")>=0 && userAgentInfo.indexOf("iPhone")>=0){
  //   localStorage.setItem('userPhone', 'iPhoneSafari')
  // }
  if(userAgentInfo.indexOf("iPhone")>=0){
    localStorage.setItem('userPhone', 'iPhone')
  }
  else if(userAgentInfo.indexOf("Android")>=0){
    localStorage.setItem('userPhone', 'Android')
  }
  else{
    localStorage.setItem('userPhone', 'Android')
  }

  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) >= 0) {
      flag = false;
      break;
    }
  }
  return flag
}
let isPc = isPhone();
localStorage.setItem('isPc', isPc)

const routePC = [
  {
    path: '/',
    name: 'Home',
    component: resolve => require(['../../src/components/PC/Home.vue'], resolve)
  }, {
    path: '/products',
    name: 'Products',
    component: resolve => require(['../../src/components/PC/Products.vue'], resolve)
  }, {
    path: '/pricing',
    name: 'Pricing',
    component: resolve => require(['../../src/components/PC/Pricing.vue'], resolve)
  }, {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: resolve => require(['../../src/components/PC/PrivacyPolicy.vue'], resolve)
  },

  // pc端路由重定向
  {
    path: '/resources',
    redirect:'/blogs',
  },
  {
    path: '/resourceDetails/:id',
    redirect:'/blogDetails/:id',
  }, 
  {
    path: '/resourceDetail/:id',
    redirect: '/blogDetails/:id'
  },

   {
    path: '/blogs',
    name: 'Blogs',
    component: resolve => require(['../../src/components/PC/Blogs.vue'], resolve)
  }, {
    path: '/features',
    name: 'Features',
    component: resolve => require(['../../src/components/PC/Features.vue'], resolve)
  }, 
  {
    path: '/blogDetails/:id',
    name: 'BlogDetails',
    component: resolve => require(['../../src/components/PC/BlogDetail.vue'], resolve),
    // meta: {
    //   title: 'Home Page - Example App',
    //   metaTags: [
    //     {
    //       name: 'description',
    //       property: 'og:description',
    //       content: 'The home page of our example app.'
    //     },
    //     {
    //       name: 'type',
    //       property: 'og:type',
    //       content: 'Arti'
    //     }
    //   ]
    // }
  }, 
  // {
  //   path: '/blogDetail/:id',
  //   name: 'BlogDetail',
  //   component: resolve => require(['../../src/components/PC/BlogDetail.vue'], resolve),
  // },
  {
    path: '/termsAndConditions',
    name: 'TermsAndConditions',
    component: resolve => require(['../../src/components/PC/TermsConditions.vue'], resolve)
  },
  {
    path: '/servicesAgreement',
    name: 'ServicesAgreement',
    component: resolve => require(['../../src/components/PC/ServicesAgreement.vue'], resolve)
  },
  {
    path: '/arcanite',
    name: 'Arcanite',
    component: resolve => require(['../../src/components/PC/Arcanite.vue'], resolve)
  },
  {
    path: '/arcaniteConnect',
    name: 'ArcaniteConnect',
    component: resolve => require(['../../src/components/PC/ArcaniteConnect.vue'], resolve)
  },
  {
    path: '/arcanitePartners',
    name: 'ArcanitePartner',
    component: resolve => require(['../../src/components/PC/ArcanitePartner.vue'], resolve)
  },
  {
    path: '/dragonVR',
    name: 'DragonVR',
    component: resolve => require(['../../src/components/PC/DragonVR.vue'], resolve)
  },
  // {
  //   path: '/equimate',
  //   name: 'Equimate',
  //   component: resolve => require(['../../src/components/PC/Equimate.vue'], resolve)
  // },
  {
	path: '/marketplace',
	name: 'Marketplace',
	component: resolve => require(['../../src/components/PC/Marketplace.vue'], resolve)
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: resolve => require(['../../src/components/PC/AboutUs.vue'], resolve)
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: resolve => require(['../../src/components/PC/Sitemap.vue'], resolve)
  }
]

const routeAPP = [
  {
    path: '/',
    name: 'Home',
    component: resolve => require(['../../src/components/APP/Home.vue'], resolve)
  },
  {
    path: '/arcanite',
    name: 'Arcanite',
    component: resolve => require(['../../src/components/APP/Arcanite.vue'], resolve)
  },
  {
    path: '/arcaniteConnect',
    name: 'ArcaniteConnect',
    component: resolve => require(['../../src/components/APP/ArcaniteConnect.vue'], resolve)
  },
  {
    path: '/arcanitePartners',
    name: 'ArcanitePartner',
    component: resolve => require(['../../src/components/APP/ArcanitePartner.vue'], resolve)
  },
  {
    path: '/dragonVR',
    name: 'DragonVR',
    component: resolve => require(['../../src/components/APP/DragonVR.vue'], resolve)
  },
  // {
  //   path: '/equimate',
  //   name: 'Equimate',
  //   component: resolve => require(['../../src/components/APP/Equimate.vue'], resolve)
  // },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: resolve => require(['../../src/components/APP/Marketplace.vue'], resolve)
  },
  {
    path: '/products',
    name: 'Products',
    component: resolve => require(['../../src/components/APP/Products.vue'], resolve)
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: resolve => require(['../../src/components/APP/Pricing.vue'], resolve)
  },

  //app路由重定向
  {
    path: '/resources',
    redirect:'/blogs',
  },
  {
    path: '/resourceDetail/:id',
    redirect:'/blogDetails/:id',
  },
  {
    path: '/resourceDetails/:id',
    redirect:'/blogDetails/:id',
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: resolve => require(['../../src/components/APP/Blogs.vue'], resolve)
  },
  // {
  //   path: '/BlogDetail/:id',
  //   name: 'BlogDetail',
  //   component: resolve => require(['../../src/components/APP/BlogsContent.vue'], resolve)
  // },
  {
    path: '/blogDetails/:id',
    name: 'BlogDetails',
    component: resolve => require(['../../src/components/APP/BlogsContent.vue'], resolve)
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: resolve => require(['../../src/components/APP/PrivacyPolicy.vue'], resolve)
  },
  {
    path: '/servicesAgreement',
    name: 'ServicesAgreement',
    component: resolve => require(['../../src/components/APP/ServicesAgreement.vue'], resolve)
  },
  {
    path: '/rooternav',
    name: 'FooterNav',
    component: resolve => require(['../../src/components/APP/FooterNav.vue'], resolve)
  }, {
    path: '/termsAndConditions',
    name: 'TermsAndConditions',
    component: resolve => require(['../../src/components/APP/TermsConditions.vue'], resolve)
  },
   {
    path: '/aboutus',
    name: 'AboutUs',
    component: resolve => require(['../../src/components/APP/AboutUs.vue'], resolve)
  }, {
    path: '/features',
    name: 'Features',
    component: resolve => require(['../../src/components/APP/Features.vue'], resolve)
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: resolve => require(['../../src/components/APP/Sitemap.vue'], resolve)
  }
]

const router = new Router({
	// mode: isPc ? 'history':'hash',
	mode: 'history',
  routes: isPc ? routePC : routeAPP,
})

export default router
