import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueParticles from 'vue-particles'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import './assets/font/font.css'
import Swiper2, { Navigation, Pagination } from 'swiper';// 这行代码很关键
import Swiper, { Autoplay} from 'swiper';
import VueMeta from 'vue-meta'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import lottie from 'vue-lottie'
import VueI18n from 'vue-i18n';
import '../src/assets/js/flexible.js' //rem
import '../src/assets/css/font.css'
import axios from 'axios'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import LazyYoutube from "vue-lazytube";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css'
import './assets/css/common.scss'
import x2js from 'x2js'  	//xml数据处理插件

// Vue.prototype._i18n = i18n;

// localStorage.setItem('country', isPc)
// wwjw
// console.log("vue",location.host)
if(location.host.indexOf("arcanite.id")>-1){
  localStorage.setItem('country', 'id')
}else if(location.host.indexOf("localhost")>-1){

}else if(location.host.indexOf(".com.au")>-1){
  localStorage.setItem('country', 'au')
}
let locale = localStorage.getItem("country") || 'au';
// locale = 'id'
import moment from "moment"
Swiper.use([Autoplay]);
import id from '../locales/id-ID.json'
import en from '../locales/en-AU.json'
// import cn from '../locales/zh-CN.json'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: locale,
  // fallbackLocale: 'en',
  messages: {
    en,
    id,
    // cn,
  }
})
Vue.component('lottie',lottie)
Vue.use(VueMeta);
Vue.use(moment);
Vue.use(VideoPlayer)
Swiper2.use([Navigation, Pagination]); // 这行代码很关键
Vue.use(VueParticles);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.config.productionTip = false
// 导入axios

Vue.use(VueVideoPlayer);
Vue.component("LazyYoutube", LazyYoutube);
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  // 或
  // window.scroll(0, 0);
});
import tldjs from 'tldjs';
Vue.prototype.$environment = 'market'; // market, enterprice, staging, localhost
if (Vue.prototype.$environment == 'market' || Vue.prototype.$environment == 'enterprice') {
  var host = tldjs.getDomain(location.host);
  axios.defaults.baseURL = 'https://api.' + host + '/v1/'
} else if (Vue.prototype.$environment == 'staging') {
  var host = 'arcnet.com.au';
  axios.defaults.baseURL = 'https://staging.' + host + '/arc-service-api/v1/'
} else {
  axios.defaults.baseURL = 'http://localhost:8088/'
}

Vue.prototype.$x2js = new x2js()	 //全局挂载到vue原型上
Vue.use(ElementUI);
Vue.use(router)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  // mounter(){
  // 	  document.dispatchEvent(new Event('render-event'))
  // }
}).$mount('#app')


// i18n.locale = 'cn'